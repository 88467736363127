.landing-service__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.landing-service__background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 120%;
  opacity: 0.3;
  filter: blur(120px);

  @include break-reverse(768) {
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -45%);
    width: 65%;
    height: 150%;
  }
}

.landing-service__title {
  margin: 0 0 $space-sm;

  @include break-reverse(768) {
    margin: 0 0 $space-md;
  }
}

.landing-service__items {
  position: relative;
  margin: 0 0 $space;

  @include break-reverse(768) {
    padding: $space ($space-md - 4);
    width: 90%;
    border: 1px solid rgba($neutral--40, 0.6);
  }

  @include break-reverse(992) {
    padding: $space-md ($space-xxl - 4);
  }
}

.landing-service__square {
  position: absolute;
  top: 50%;
  width: 18px;
  height: calc(100% + 18px);

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    background: $neutral--0;
    border: 1px solid $primary--0;
    border-radius: 2px;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.landing-service__items-list {
  @include break-reverse(768) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 (-$space-xs);
  }
}

.landing-service__item {
  @include break-reverse(768) {
    padding: ($space / 2) $space-xs;
  }
}

.landing-service__item--1,
.landing-service__item--3 {
  font: 500 32px / 1.3 $clashGrotesk;
  white-space: nowrap;

  @include break-reverse(768) {
    font-size: 40px;
  }

  @include break-reverse(1241) {
    font-size: 48px;
  }
}

.landing-service__item--1 {
  @include break(767) {
    margin: 0 0 $space-xs;
  }
}

.landing-service__item--2 {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 $space-xs 0 $space;

  @include break(767) {
    margin: 0 0 $space;
    padding: 0 0 0 $space;
  }
}

.landing-service__item-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ($space-xs + 2) ($space - 4);
  font: 500 16px / 1.5 $satoshi;
  background: $neutral--0;
  color: $neutral--100;
  border-radius: 6px;
}

.landing-service__item-icon--sales-arrow {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(-25%, -20%);
  min-width: 23px;
  height: 25px;
}

.landing-service__item-icon--sales-force {
  margin: 0 0 0 $space-sm;
  min-width: 64px;
}

.landing-service__square--left {
  left: 0;
  transform: translate(-50%, -50%);
}

.landing-service__square--right {
  right: 0;
  transform: translate(50%, -50%);
}

.landing-service__description {
  margin: 0;
}
