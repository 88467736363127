.table {
  font: 500 16px / 1.5 $satoshi;
  letter-spacing: 0.2px;
}

.table--desktop {
  thead {
    th {
      &:has(.table__logo) {
        position: relative;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba($primary--40, 0.4);
    }

    th {
      text-align: left;
    }
  }

  th,
  td {
    position: relative;

    @include break-reverse(768) {
      padding: $space-sm $space;
    }

    @include break-reverse(1241) {
      padding: $space $space-md;
    }
  }

  td {
    text-align: center;
  }
}

.table--mobile {
  width: 100%;

  .table__cell {
    padding: $space-xs $space-sm;
  }

  .logo-block__logo-image {
    width: 45px;
  }

  .logo-block__logo-text {
    display: none;
  }
}

.table__block {
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 12px;
  overflow: hidden;

  &:not(:last-of-type) {
    margin: 0 0 $space;
  }
}

.table__block-row {
  min-height: 45px;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba($primary--40, 0.4);
  }
}

.table__block-row--title {
  text-align: center;
}

.table__block-row--data {
  display: flex;

  .table__cell {
    &:first-of-type {
      justify-content: center;
      min-width: 85px;
      max-width: 25%;
      width: 100%;
    }

    &:last-of-type {
      width: 100%;
      border-left: 1px solid rgba($primary--40, 0.4);
    }
  }
}

.table__block-row--primary {
  background: linear-gradient(
    to right,
    rgba(#0d1c3a, 0.7) 0%,
    rgba(#0c0f25, 0.3) 100%
  );
}

.table__cell {
  display: inline-flex;
  align-items: center;
}

.table__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  background: #0d1c3a;
  border-radius: 50%;

  @include break-reverse(768) {
    width: 45px;
    height: 45px;
  }
}
