.landing-ui {
  padding: ($space-md * 2) 0;

  @include break-reverse(768) {
    padding: ($space-md * 2) 0 ($space-xl * 2);
  }
}

.landing-ui__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.landing-ui__background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 22.5%);
  width: 110vw;
  height: 130vw;
  background: linear-gradient(
    180deg,
    rgba($primary--20, 0.8) -8.19%,
    rgba($primary--20, 0) 100%
  );
  filter: blur(50px);

  @include break-reverse(768) {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: 90vw;
    filter: blur(120px);
  }

  @include break-reverse(1241) {
    width: 1000px;
    height: 900px;
  }
}

.landing-ui__items {
  width: 100%;
}

.landing-ui__items-list {
  @include break-reverse(768) {
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-template-rows: repeat(16, auto);
    column-count: 2;
    grid-column-gap: $space-md;
    grid-row-gap: $space-md;
  }

  @include break-reverse(1241) {
    grid-column-gap: $space-xl;
    grid-row-gap: $space-xl;
  }
}

.landing-ui__item {
  display: flex;
  justify-content: center;
  width: 100%;

  &:not(:last-of-type) {
    @include break(767) {
      margin: 0 0 $space-sm;
    }
  }

  &:last-of-type,
  &:nth-of-type(3) {
    @include break-reverse(768) {
      margin: 0;
    }
  }

  .ui-card,
  .ui-card__content {
    display: flex;
    flex-direction: column;
  }

  .ui-card {
    padding: $space-xl $space-sm $space-sm;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba($primary--80, 0.9) 0%,
      rgba($primary--80, 0.45) 100%
    );
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 10px;
    overflow: hidden;

    @include break(767) {
      align-items: center;
    }

    @include break-reverse(768) {
      padding: $space-lg;
    }

    @include break-reverse(1241) {
      padding: ($space-md * 2);
    }
  }

  .ui-card--background {
    position: relative;

    * {
      position: relative;
      z-index: 5;
    }
  }

  .ui-card__background {
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .ui-card__content {
    max-width: 475px;
  }

  .ui-card__visual {
    display: flex;
    justify-content: center;
    margin: 0 0 $space-xl;
    width: 100%;

    @include break-reverse(768) {
      justify-content: flex-start;
    }

    img {
      @include break-reverse(768) {
        width: 100%;
      }
    }
  }

  .ui-card__title {
    margin: 0 0 $space-sm;
    font: 500 18px / 1.3 $clashGrotesk;
    letter-spacing: 0.2px;
  }

  .ui-card__description,
  .ui-card__actions-title {
    font: 400 18px / 1.3 $satoshi;
    color: $neutral--10;
  }

  .ui-card__description {
    margin: 0 0 $space-sm;
  }

  .ui-card__actions {
    margin: 0;

    @include break(991) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include break-and-break-reverse(576, 767) {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    @include break-reverse(992) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    @include break-reverse(1241) {
      flex-wrap: nowrap;
    }
  }

  .ui-card__actions-title {
    @include break-and-break-reverse(576, 767) {
      width: 100%;
    }

    @include break-and-break-reverse(992, 1240) {
      width: 100%;
    }
  }

  .ui-card__button {
    white-space: nowrap;
    background: $primary--80;

    @include break(991) {
      width: 100%;
    }

    @include break(575) {
      margin: 0 auto;
    }

    @include break-and-break-reverse(576, 767) {
      width: 48%;
    }

    @include break-and-break-reverse(992, 1240) {
      width: 48%;
    }

    &:hover {
      background: $neutral--0;
    }
  }
}

.landing-ui__item--mappings {
  @include break-reverse(768) {
    grid-area: 1 / 1 / 8 / 6;
  }

  .ui-card__visual {
    img {
      @include break(767) {
        max-height: 250px;
      }

      @include break-reverse(768) {
        max-width: 420px;
      }
    }
  }
}

.landing-ui__item--scenarios {
  @include break-reverse(768) {
    grid-area: 1 / 6 / 6 / 12;
  }

  .ui-card__background {
    right: 0;
    transform: translate(5%, -5%);
    width: 325px;

    @include break-reverse(1241) {
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
    }
  }

  .ui-card__visual {
    img {
      @include break(767) {
        max-height: 135px;
      }

      @include break-reverse(768) {
        max-width: 325px;
      }
    }
  }
}

.landing-ui__item--service {
  @include break-reverse(768) {
    grid-area: 8 / 1 / 16 / 6;
  }

  .ui-card__visual {
    img {
      @include break(767) {
        max-height: 250px;
      }

      @include break-reverse(768) {
        max-width: 425px;
      }
    }
  }
}

.landing-ui__item--objects {
  @include break-reverse(768) {
    grid-area: 6 / 6 / 12 / 12;
  }

  .ui-card__background {
    right: 0;
    transform: translate(35%, 0);
    width: 40%;
    height: 100%;
  }

  .ui-card__visual {
    img {
      @include break(767) {
        max-height: 130px;
      }

      @include break-reverse(768) {
        max-width: 480px;
      }
    }
  }
}

.landing-ui__item--data {
  @include break-reverse(768) {
    grid-area: 12 / 6 / 17 / 12;
  }

  .ui-card__visual {
    margin: (-$space-xl) 0 0;

    @include break-reverse(768) {
      margin: (-$space-lg) 0 0 (-$space-sm);
    }

    @include break-reverse(1241) {
      margin: (-$space-md * 2) 0 0 (-$space-sm);
    }

    img {
      @include break(767) {
        max-height: 275px;
      }

      @include break-reverse(768) {
        max-width: 415px;
      }
    }
  }
}
