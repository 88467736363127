.landing-banner {
  .banner-block__title {
    @include break-reverse(768) {
      text-align: center;
    }
  }

  .banner-block__actions {
    @include break-reverse(768) {
      justify-content: center;
    }
  }
}
