.header {
  position: relative;
  padding: ($space / 2) 0;
  margin: 0 0 $space-lg;
  font: 400 16px / 1.5 $satoshi;
  background: $primary--80;

  @include break-reverse(992) {
    padding: $space-sm 0;
    background: $primary--100;
  }
}

.header--open {
  background: $primary--100;

  .header__block--navigation {
    display: block;
  }

  .header__block--actions {
    display: none;
  }
}

.header__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;

  @include break-reverse(992) {
    padding: ($space / 2);
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 50px;
  }
}

.header__block {
  display: flex;
  align-items: center;
}

.header__logo {
  .logo-block__logo-text {
    @include break(1125) {
      display: none;
    }
  }
}

.header__block--navigation {
  @include break(991) {
    position: absolute;
    z-index: 20;
    display: none;
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba($primary--100, 0.8);
    box-shadow: 0 2px 4px rgba($neutral--100, 0.2);
    backdrop-filter: blur(3px);
  }

  > .header__mobile-menu {
    @include break-reverse(992) {
      display: none;
    }
  }

  > .header__menu {
    @include break(991) {
      display: none;
    }
  }
}

.header__menu-button {
  margin: 0 $space-xs 0 0;

  @include break-reverse(992) {
    display: none;
  }

  .icon {
    width: 40px;
    height: 32px;
  }
}

.header__menu-list {
  align-items: flex-start;

  @include break-reverse(992) {
    flex-direction: row;
    align-items: center;
  }
}

.header__menu-item {
  cursor: pointer;

  &:hover {
    span {
      .icon {
        @include break-reverse(992) {
          transform: translate(0, -50%) rotate(180deg);
        }
      }
    }

    .header__submenu {
      @include break-reverse(992) {
        display: block;
      }
    }
  }

  &:not(:last-of-type) {
    margin: 0 0 $space-sm;

    @include break-reverse(992) {
      margin: 0 $space 0 0;
    }
  }

  &:not(.header__menu-item--dropdown) {
    .link {
      &:hover {
        color: rgba($neutral--0, 0.5) !important;
        transition: all 0.1s ease;
      }
    }
  }
}

.header__menu-item--dropdown {
  position: relative;

  > span {
    position: relative;
    padding: 0 $space-sm 0 0;

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 10px;
      height: 5px;
      transition: all 0.2s ease;
    }
  }
}

.header__menu-item--open {
  > span {
    .icon {
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  .header__submenu {
    display: block;
  }
}

.header__submenu {
  display: none;

  @include break-reverse(992) {
    position: absolute;
    z-index: 10;
    top: 100%;
    right: 0;
    padding: $space-xs 0 0;
    min-width: 225px;
  }
}

.header__submenu-list {
  align-items: flex-start;

  @include break-reverse(992) {
    padding: $space-xs;
    background: linear-gradient(to bottom, $primary--80, $primary--90);
    border: 0.75px solid rgba($neutral--40, 0.6);
    border-radius: 12px;
  }
}

.header__submenu-item {
  width: 100%;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba($neutral--0, 0.05);
  }

  .link {
    padding: $space-xs;
  }
}

.header__mobile-menu {
  padding: ($space - 4);
  width: 100%;

  .header__menu {
    margin: 0 0 $space-sm;
  }

  .header__submenu {
    padding: $space-xs;
  }

  .header__book-button {
    @include break(991) {
      order: 10;
      margin: 0 0 0 auto;
    }
  }
}

.header__actions {
  display: flex;
  align-items: center;
  column-gap: $space-xs;
}

.header__log-button,
.header__book-button,
.header__sign-button {
  @include break(991) {
    font-size: 16px !important;
  }

  &:hover {
    color: rgba($neutral--0, 0.5);
    transition: all 0.1s ease;
  }
}

.header__log-button {
  padding: $space-xs $space-sm;

  @include break-reverse(768) {
    padding: $space-xs $space;
  }
}

.header__book-button {
  @include break(489) {
    display: none;
  }

  &:hover {
    .icon {
      stroke: $neutral--100;
    }
  }

  .btn__content {
    gap: $space-xs;
  }

  .icon {
    transform: translate(0, -1px);
    width: 20px;
    height: 20px;
    stroke: $neutral--0;
    stroke-width: 1.5px;
  }
}
