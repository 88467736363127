.competitor-banner {
  padding: $space 0 $space-md;

  @include break-reverse(768) {
    padding: $space 0 ($space-md * 2);
  }

  .banner-block__title {
    font-weight: 500;

    @include break-reverse(1241) {
      font-size: 56px;
    }
  }
}
