.builder-custom-build {
  display: grid;
  grid-template-columns: 1fr;
  padding: $space-xl 0;

  @include break-reverse(650) {
    padding: 52px 0 ($space-md * 2);
  }

  @include break-reverse(768) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: $space-md;
  }

  @include break-reverse(1121) {
    gap: 76px;
  }
}

.builder-custom-build__content {
  position: relative;
  z-index: 2;
  padding: $space 0 0;

  @include break-reverse(650) {
    padding: $space-xl 0 0;
  }

  @include break(767) {
    order: 1;
  }

  @include break-reverse(768) {
    align-items: flex-start;
    padding: 0;
  }
}

.builder-custom-build__title {
  max-width: 100%;

  @include break-and-break-reverse(481, 767) {
    font-size: 26px;
  }

  @include break-reverse(650) {
    font-size: $space-md;
  }

  @include break-reverse(768) {
    font-size: 20px;
    text-align: left;
  }

  @include break-reverse(992) {
    font-size: 26px;
  }

  @include break-reverse(1240) {
    font-size: $size-point * 4;
  }
}

.builder-custom-build__list {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  gap: 18px;

  @include break-reverse(768) {
    gap: $space-xs;
  }

  @include break-reverse(992) {
    gap: $space-sm;
  }

  @include break-reverse(1121) {
    gap: $space;
  }
}

.builder-custom-build__desc {
  line-height: 1.2;
  text-align: center;

  @include break-reverse(768) {
    font-size: 14px;
    text-align: left;
  }

  @include break-reverse(992) {
    font-size: $size-point * 2;
  }
}

.builder-custom-build__image {
  position: relative;
  top: 0;
  right: 0;

  @include break-and-break-reverse(481, 767) {
    width: 90%;
    margin: 0 auto;
  }
}
