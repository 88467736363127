.container {
  margin: 0 auto;
  padding: 0 ($space - 4);
  max-width: 1200px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
