.landing-setup {
  &:before {
    background: $primary--80;
    border-top: 1px solid $primary--40;
    border-radius: 12px 12px 0 0;

    @include break-reverse(768) {
      border-radius: 26px 26px 0 0;
    }

    @include break-reverse(992) {
      border-radius: 40px 40px 0 0;
    }
  }
}

.landing-setup__title {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
  }
}

.landing-setup__content {
  width: 100%;
}

.landing-setup__top {
  margin: 0 0 $space-sm;

  @include break-reverse(768) {
    flex-direction: row;
    align-items: normal;
    margin: 0 -4vw ($space-md * 2);
  }

  @include break-reverse(992) {
    margin: 0 -4vw ($space-xl * 2);
  }

  @include break-reverse(1241) {
    margin: 0 (-$space-xl * 2) ($space-xl * 2);
  }

  .landing-setup__block {
    @include break-reverse(768) {
      padding: 0 4vw;
      width: 50%;
    }

    @include break-reverse(1241) {
      padding: 0 ($space-xl * 2);
    }

    .setup-card {
      @include break-reverse(768) {
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.landing-setup__middle {
  margin: 0 0 ($space-md * 2);

  @include break-reverse(992) {
    margin: 0 0 ($space-xl * 2);
  }
}

.landing-setup__block {
  display: flex;
  justify-content: center;
  width: 100%;

  .setup-card,
  .setup-card__content {
    display: flex;
    flex-direction: column;
  }

  .setup-card {
    @include break(767) {
      align-items: center;
    }
  }

  .setup-card__content {
    max-width: 475px;
  }

  .setup-card__visual {
    @include break(767) {
      display: flex;
      justify-content: center;
      margin: 0 0 $space;
    }
  }

  .setup-card__title {
    margin: 0 0 $space-sm;
    font: 500 18px / 1.3 $clashGrotesk;
    letter-spacing: 0.2px;
  }

  .setup-card__description {
    font: 400 18px / 1.3 $satoshi;
    color: $neutral--10;
  }

  .setup-card__actions {
    margin: $space-md 0 0;

    @include break-reverse(768) {
      justify-content: flex-start;
      margin: $space 0 0;
    }
  }
}

.landing-setup__block--parts,
.landing-setup__block--fields,
.landing-setup__block--api {
  @include break(767) {
    padding: $space-sm;
    background: linear-gradient(
      180deg,
      rgba($primary--80, 0.9) 0%,
      rgba($primary--80, 0.45) 100%
    );
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 10px;
  }
}

.landing-setup__block--parts {
  @include break(767) {
    margin: 0 0 $space-sm;
  }

  .setup-card__visual {
    img {
      max-height: 450px;
    }
  }
}

.landing-setup__block--fields {
  .setup-card__visual {
    img {
      max-height: 485px;
    }
  }
}

.landing-setup__block--api {
  .setup-card {
    @include break-reverse(768) {
      flex-direction: row;
      align-items: center;
    }
  }

  .setup-card__visual {
    @include break-reverse(768) {
      width: 57.5%;
    }

    img {
      max-height: 585px;

      @include break-reverse(768) {
        width: 100%;
      }
    }
  }

  .setup-card__content {
    @include break-reverse(768) {
      padding: 0 0 0 5vw;
      width: 42.5%;
    }

    @include break-reverse(1241) {
      padding: 0 0 0 ($space-xl * 2);
    }
  }
}

.landing-setup__block--connectors {
  .setup-card {
    width: 100%;
  }

  .setup-card__visual {
    @include break(767) {
      order: 1;
      margin: 0 (-$space + 4);
    }

    img {
      width: 100%;
    }
  }

  .setup-card__content {
    margin: 0 auto 0 0;

    @include break(767) {
      order: 0;
      margin: 0 auto $space-lg;
    }
  }

  .setup-card__title {
    @include break(767) {
      display: none;
    }
  }
}
