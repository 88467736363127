.landing-cases {
  padding: ($space-md * 2) 0 $space-xl;
}

.landing-cases__title {
  margin: 0;
}

.landing-cases__slider {
  width: 100%;

  .alice-carousel__dots {
    margin: (-$space) 0 0;
  }
}

.landing-cases__slide {
  margin: 0 $space-sm;
  padding: $space-md 0;
  height: 100%;

  @include break-reverse(992) {
    padding: ($space-md * 2) 0 $space-md;
  }

  .case-card {
    display: flex;
    padding: $space-sm $space $space;
    height: 100%;
    background: $primary--80;
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 14px;
    opacity: 0.65;
    transition: all 0.2s ease;
    overflow: hidden;

    @include break-reverse(768) {
      padding: $space $space-md $space-md;
    }
  }

  .case-card--active {
    position: relative;
    opacity: 1;

    &:hover {
      box-shadow: 0 -6px 14px 0 rgba($neutral--80, 0.6);
    }

    .case-card__link {
      position: static;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .icon {
        min-width: 24px;
        height: 24px;
      }
    }
  }

  .case-card__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 $space;
    width: 100%;
  }

  .case-card__topic {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-xs;
    width: fit-content;
    font: 600 14px / 1 $clashGrotesk;
    background: $primary--60;
    color: rgba($neutral--0, 0.8);
    border: 1.5px solid $primary--40;
    border-radius: 6px;
  }

  .case-card__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: center;
    font: 400 16px / 1.4 $satoshi;
    letter-spacing: 0.2px;
    -webkit-line-clamp: 3;
    text-wrap: balance;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .case-card__logo {
    margin: 0 0 $space;

    img {
      height: 55px;

      @include break-reverse(1241) {
        height: 65px;
      }
    }
  }
}
