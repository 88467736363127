.competitor-comparison {
  padding: $space-md 0;

  @include break-reverse(768) {
    padding: ($space-md * 2) 0;
  }
}

.competitor-comparison__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.competitor-comparison__background {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(35%, -5%) rotate(50deg);
  width: 70vw;
  height: 50vw;
  background: #005c9e;
  border-radius: 70vw;
  opacity: 0.2;
  filter: blur(100px);

  @include break-reverse(1241) {
    width: 830px;
    height: 600px;
    border-radius: 830px;
  }
}

.competitor-comparison__table--desktop {
  .table__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(#0d1c3a, 0.7) 0%,
      rgba(#0c0f25, 0.3) 100%
    );
    border-radius: 8px 8px 0 0;
  }
}

.competitor-comparison__table--mobile {
  width: 100%;
}
