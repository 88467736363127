.builder-hero {
  padding: $space-xs 0 $space-xl;
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }

  @include break-reverse(650) {
    padding: $space 0 ($space-md * 2);
  }
}

.builder-hero__background {
  position: absolute;
  z-index: 1;
  bottom: 5%;
  width: 50vw;
  height: 8vw;
  filter: blur(10vw);
  opacity: 0.8;

  @include break-reverse(650) {
    bottom: 8%;
  }

  @include break-reverse(1241) {
    bottom: 40px;
    width: 550px;
    height: 90px;
    filter: blur(120px);
  }
}

.builder-hero__title,
.builder-hero__subtitle {
  max-width: 100%;
  font: 26px / 1.1 $clashGrotesk;
  text-align: center;
  text-wrap: balance;

  @include break-reverse(768) {
    font-size: $size-point * 4;
  }

  @include break-reverse(1121) {
    font-size: $size-point * 5;
  }

  @include break-reverse(1241) {
    font-size: $size-point * 6;
  }
}

.builder-hero__title {
  font-weight: 500;
}

.builder-hero__subtitle {
  margin: 0 0 $space-lg;
  color: $neutral--30;
}

.builder-hero__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-sm;
  max-width: 335px;
  margin: $space-xl 0 $space-md;

  @include break-reverse(650) {
    flex-direction: unset;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 600px;
    margin: ($space-md * 2) 0 $space-md;
  }

  @include break-reverse(1121) {
    flex-wrap: nowrap;
    gap: $space-md;
    max-width: 100%;
    margin: ($space-md * 2) 0 $space-sm;
  }
}

.builder-hero__item {
  display: flex;
  align-items: center;
  gap: $space-xs;
  border-bottom: 1px solid $primary--40;

  @include break-and-break-reverse(650, 1120) {
    flex-basis: calc((100% - $space-md) / 2);
  }
}

.builder-hero__description {
  font: 400 16px / 1.75 $satoshi;

  &--light {
    flex-shrink: 0;
    opacity: 0.8;
  }
}

.builder-hero__note {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-sm;
  width: 100%;

  img {
    display: block;
    height: $size-point * 3;
  }

  &:before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 400px;
    height: 0.8px;
  }

  &::before {
    background: linear-gradient(
      90deg,
      rgba($primary--40, 0) 0%,
      $neutral--40 100%
    );
  }

  &::after {
    background: linear-gradient(
      270deg,
      rgba($primary--40, 0) 0%,
      $neutral--40 100%
    );
  }
}
