.landing-ai {
  &:before {
    background: $primary--80;
    border-top: 1px solid $primary--40;
    border-radius: 12px 12px 0 0;

    @include break-reverse(768) {
      border-radius: 26px 26px 0 0;
    }

    @include break-reverse(992) {
      border-radius: 40px 40px 0 0;
    }
  }
}

.landing-ai__button {
  position: absolute;
  top: 0;
  right: 0;

  @include break(1240) {
    transform: translate(0, -150%);
  }
}

.landing-ai__visual {
  img {
    @include break(767) {
      max-height: 700px;
    }
  }
}
