:root {
  /**
   * Set Scrollbar css variables
   **/
  --scrollbar-width: 8px;
  --scrollbar-height: 8px;
  --scrollbar-border-radius: 3px;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
  --scrollbar-track-color: rgba(0, 0, 0, 0.05);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Scrollbar
 */
html.noMacOs ::-webkit-scrollbar {
  width: var(--scrollbar-width, 8px);
  height: var(--scrollbar-height, 8px);
}
html.noMacOs ::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, rgba(0, 0, 0, 0.2));
  border: 0 solid;
  border-radius: var(--scrollbar-border-radius, 4px);
}
html.noMacOs ::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color, rgba(0, 0, 0, 0.05));
}
html.noMacOs ::-webkit-scrollbar-corner {
  background: var(--scrollbar-track-color, rgba(0, 0, 0, 0.05));
  border: 0 solid;
  border-radius: var(--scrollbar-border-radius, 4px);
}
