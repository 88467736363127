.landing-pattern__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.landing-pattern__background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -225%);
  width: 100vw;
  height: 20vw;
  filter: blur(120px);

  @include break-reverse(1241) {
    transform: translate(-50%, -145%);
    width: 1150px;
    height: 275px;
  }
}

.landing-pattern__navigation {
  padding: $space-xs;
  border: solid rgba($neutral--40, 0.6);
  border-width: 1px 1px 0 1px;
  border-radius: 24px 24px 0 0;

  .tabs__item {
    @include break(767) {
      padding: $space-xxs $space-sm;
      font-size: 16px;
    }

    &:hover {
      border-color: $neutral--50;
    }
  }
}

.landing-pattern__content {
  position: relative;
  margin: 0 0 $space-lg;
  padding: $space-md 0 0;

  @include break-reverse(576) {
    padding: ($space / 2);
    background: rgba($primary--70, 0.4);
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 14px;
  }

  &:before {
    @include break(575) {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 0.75px;
      background: rgba($neutral--40, 0.6);
    }
  }

  .pattern-card {
    @include break-reverse(576) {
      padding: $space-md;
      background: linear-gradient(to bottom, $primary--80, $primary--90);
      border-radius: 10px;
    }

    @include break-reverse(992) {
      padding: $space-xl;
    }
  }

  .pattern-card__visual {
    display: flex;
    justify-content: center;
    margin: 0 0 $space;
    max-width: 875px;

    @include break-reverse(768) {
      margin: 0 0 $space-lg;
    }

    img {
      @include break(575) {
        max-width: 400px;
      }
    }
  }

  .pattern-card__description {
    margin: 0 auto;
    max-width: 750px;

    .link {
      font-weight: 600;

      &:before {
        width: 96%;
      }
    }

    .link__content {
      padding: 0 $space 0 0;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -45%);
      width: 20px;
    }
  }
}

.landing-pattern__actions {
  margin: 0;
}
