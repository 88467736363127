.partners-block {
  padding: ($space-md * 2) 0 0;
}

.partners-block__title {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 $space-xl;
  }
}

.partners-block__items-list {
  @include break-reverse(576) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 (-$space / 2);
  }
}

.partners-block__item {
  margin: 0 0 $space;

  @include break-reverse(576) {
    padding: 0 ($space / 2);
  }

  img {
    max-height: 45px;

    @include break-reverse(768) {
      max-height: 53.5px;
    }

    @include break-reverse(992) {
      max-height: 62px;
    }
  }
}
