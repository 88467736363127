.footer {
  padding: $space-lg 0;
  font: 400 16px / 1.5 $satoshi;
  background: $primary--100;
  overflow: hidden;

  @include break-reverse(576) {
    padding: $space-md 0;
    background: $primary--80;
  }
}

.footer__body {
  display: flex;
  flex-direction: column;

  @include break-reverse(576) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 (-$space);
  }

  @include break-reverse(1241) {
    flex-wrap: nowrap;
  }
}

.footer__block {
  @include break-reverse(576) {
    padding: 0 $space;
  }

  &:not(:last-of-type) {
    @include break(575) {
      margin: 0 0 $space-lg;
    }
  }
}

.footer__block--logo {
  @include break-and-break-reverse(576, 1230) {
    margin: 0 0 $space-sm;
    width: 20%;
  }
}

.footer__block--navigation {
  @include break(575) {
    margin: 0 0 $space-lg;
  }

  @include break-and-break-reverse(576, 1240) {
    margin: 0 0 $space-sm;
    width: 80%;
  }
}

.footer__block--contacts,
.footer__block--copyright {
  @include break-and-break-reverse(576, 1240) {
    width: 50%;
  }
}

.footer__block--contacts {
  @include break-reverse(768) {
    display: flex;
    align-items: center;
  }
}

.footer__block--copyright {
  @include break-reverse(576) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    text-align: right;
  }
}

.footer__logo {
  img {
    width: 50px;
  }
}

.footer__navigation-list {
  display: flex;
  flex-direction: column;

  @include break-reverse(576) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.footer__navigation-item {
  &:not(:last-of-type) {
    margin: 0 0 $space-xs;

    @include break-reverse(576) {
      margin: 0 $space-sm 0 0;
    }
  }
}

.footer__navigation-link {
  text-decoration: none;
}

.footer__contact-item {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.16px;

  &:not(:last-of-type) {
    margin: 0 0 $space-xs;

    @include break-reverse(576) {
      margin: 0 $space-sm 0 0;
    }
  }

  .icon {
    margin: 0 ($space / 2) 0 0;
    width: 24px;
    height: 24px;
  }
}

.footer__copyright-item {
  margin: 0;
  letter-spacing: 0.16px;
  color: rgba($neutral--0, 0.7);

  &:last-of-type {
    @include break-and-break-reverse(576, 767) {
      width: 100%;
    }
  }
}
