/*----- page styles ----- */
.page__title,
.page__subtitle {
  max-width: 350px;
  text-align: center;
  text-wrap: balance;
}

.page__title {
  margin: 0 0 $space;
  font: 500 24px / 1.35 $clashGrotesk;

  @include break-reverse(768) {
    margin: 0 0 $space-md;
    max-width: 550px;
    font-size: 30px;
  }

  @include break-reverse(992) {
    max-width: 750px;
    font-size: 36px;
  }
}

.page__subtitle {
  margin: 0 0 $space-lg;
  font: 400 16px / 1.35 $satoshi;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
    max-width: 600px;
    font-size: 18px;
  }

  @include break-reverse(992) {
    max-width: 800px;
  }
}

.page__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 $space-lg;
  width: 100%;

  @include break-reverse(768) {
    margin: 0 0 $space-xxl;
  }
}

.page__button {
  max-width: 335px;
  width: 100%;

  @include break-reverse(768) {
    width: fit-content;
  }
}

.page__background {
  z-index: 0;
  background: $primary--20;

  @include break-reverse(1241) {
    filter: blur(120px);
  }
}
