.landing-complex {
  padding: ($space-md * 2) 0;
}

.landing-complex__title {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
  }
}

.landing-complex__items {
  width: 100%;
}

.landing-complex__items-list {
  @include break-reverse(768) {
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    flex-wrap: wrap;
    column-gap: $space-md;
  }
}

.landing-complex__item {
  padding: $space-sm;
  width: 100%;
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 10px;

  @include break-reverse(768) {
    padding: $space;
    width: calc(50% - 16px);
  }

  @include break-reverse(992) {
    width: calc(100% / 3 - 64px / 3);
  }

  @include break-reverse(1241) {
    padding: $space-md;
  }

  &:not(:last-of-type) {
    @include break(991) {
      margin: 0 0 $space-md;
    }
  }

  .complex-card {
    margin: 0 auto;
    max-width: 475px;
    height: 100%;
    overflow: hidden;

    @include break-reverse(768) {
      justify-content: space-between;
    }
  }

  .complex-card__top,
  .complex-card__bottom {
    width: 100%;

    > * {
      width: 100%;
    }
  }

  .complex-card__top {
    margin: 0 0 $space;

    @include break-reverse(768) {
      margin: 0 0 $space-md;
    }

    @include break-reverse(768) {
      margin: 0 0 $space-lg;
    }
  }

  .complex-card__title {
    margin: 0 0 $space;
    max-width: 225px;
    text-align: center;
    font: 500 18px / 1.5 $clashGrotesk;
    text-wrap: balance;
  }

  .complex-card__visual {
    display: flex;
    justify-content: center;
    margin: 0 0 $space-lg;

    img {
      max-height: 250px;
    }
  }

  .complex-card__description,
  .complex-card__examples {
    * {
      font: 400 16px / 1.4 $satoshi;
    }
  }

  .complex-card__description-item {
    &:not(:last-of-type) {
      margin: 0 0 $space-xs;
    }

    &:before {
      content: '';
      display: inline-block;
      margin: 0 $space-xxs 3px 0;
      width: 4px;
      height: 4px;
      background: $neutral--0;
      border-radius: 50%;

      @include break-reverse(768) {
        margin: 0 $space-xxs 4px 0;
      }
    }
  }

  .complex-card__examples-title {
    margin: 0 0 $space-xxs;
  }

  .complex-card__examples-list {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .complex-card__examples-link {
    &:before {
      left: auto;
      right: 0;
      width: calc(100% - 26px);
    }

    img {
      margin: 0 $space-xxs 0 0;
      width: 22px;
      height: 22px;
    }
  }

  .complex-card__actions {
    margin: $space 0 0;
  }
}
