/*----- constants ----- */
$size-point: 8px;

/*----- base colors ----- */
$primary--100: #080a1e;
$primary--90: #090c21;
$primary--80: #0b1028;
$primary--70: #10152f;
$primary--60: #1a1f39;
$primary--50: #1c2e4a;
$primary--40: #333852;
$primary--35: #315187;
$primary--30: #6c57f2;
$primary--25: #5573a7;
$primary--20: #4c81d0;
$primary--15: #6c97ec;
$primary--10: #9384f9;
$primary--0: #cadefb;

$neutral--100: #0f1c26;
$neutral--80: #475673;
$neutral--70: #6c708e;
$neutral--60: #7c819b;
$neutral--50: #969bb5;
$neutral--40: #aeb3cd;
$neutral--30: #e0e5ff;
$neutral--20: #d9d9d9;
$neutral--10: #eff1ff;
$neutral--0: #fff;

$additional--purple: #8960ec;

/*----- font-families ----- */
$clashGrotesk: 'Clash Grotesk', Avenir, Montserrat, Corbel, 'URW Gothic',
  source-sans-pro, sans-serif;
$satoshi: 'Satoshi', Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro,
  sans-serif;

/*----- spaces----- */
$space-xxs: $size-point / 2;
$space-xs: $size-point;
$space-sm: $size-point * 2;
$space: $size-point * 3;
$space-md: $size-point * 4;
$space-lg: $size-point * 5;
$space-xl: $size-point * 6;
$space-xxl: $size-point * 7;
