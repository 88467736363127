/*----- base styles ----- */
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  word-break: normal;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;

  * {
    margin: 0;
    padding: 0;
    color: $neutral--0;
  }

  h1,
  h2,
  h3 {
    p {
      margin: 0;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

img,
svg {
  display: inline-flex;
  max-width: 100%;
  width: auto;
}
