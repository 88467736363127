.landing-pricing {
  padding: $space-xl 0;
}

.landing-pricing__body {
  padding: $space-xl $space-sm;
  background: rgba($primary--70, 0.4);
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 14px;

  @include break-reverse(768) {
    padding: $space-md 0 $space-xl;
  }

  @include break-reverse(992) {
    padding: $space-xl 0 ($space-md * 2);
  }
}

.landing-pricing__title {
  @include break-reverse(768) {
    margin: 0 0 $space;
  }
}

.landing-pricing__subtitle {
  margin: 0 0 $space-md;
}

.landing-pricing__actions {
  margin: 0;
}
