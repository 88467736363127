.landing-building-blocks {
  @include break(767) {
    margin: 0 (-$space + 4);
    padding: 0 ($space - 4) ($space-md * 2);
  }
}

.landing-building-blocks__body {
  position: relative;
  padding: ($space-md * 2) 0 0;

  @include break-reverse(768) {
    padding: ($space-xl * 2) 0;
  }

  > * {
    position: relative;
    z-index: 5;
  }
}

.landing-building-blocks__background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 5%);
  width: 65vw;
  height: 95%;
  background: linear-gradient(
    180deg,
    rgba($primary--20, 0.8) -8.19%,
    rgba($primary--20, 0) 100%
  );
  filter: blur(90px);

  @include break-reverse(576) {
    transform: translate(-50%, 5%);
    width: 35vw;
    height: 95%;
    filter: blur(10vw);
  }

  @include break-reverse(1241) {
    width: 315px;
  }
}

.landing-building-blocks__items {
  width: 100%;
  border-radius: 14px;

  @include break-reverse(576) {
    padding: $space-sm;
    border: 1px solid rgba($neutral--40, 0.6);
  }
}

.landing-building-blocks__items-listWrapper {
  overflow: hidden;
}

.landing-building-blocks__items-list {
  transform: translate(1px, 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $primary--80;
  border-radius: 12px;

  @include break-reverse(576) {
    flex-direction: row;
    align-items: normal;
    flex-wrap: wrap;
  }
}

.landing-building-blocks__item {
  display: flex;
  width: 100%;
  border: solid $primary--40;
  border-width: 0 1px 1px 0;

  @include break-reverse(576) {
    width: 50%;
  }

  @include break-reverse(768) {
    width: calc(100% / 3);
  }

  @include break-reverse(992) {
    width: 25%;
  }

  .building-block-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $space;
    width: 100%;
    transition: all 0.2s ease;

    @include break-reverse(768) {
      padding: $space-md;
    }

    .svg-jig-bg {
      fill: $primary--80;
      transition: all 0.2s ease;
    }

    &:has(a:hover) {
      background: $primary--60;
      transition: all 0.2s ease;

      .svg-jig-bg {
        fill: $primary--60;
      }
    }
  }

  .building-block-card__title {
    position: static;
    display: flex;
    align-items: center;
    margin: 0 0 $space-xs;
    font: 500 16px / 1.6 $clashGrotesk;
    letter-spacing: 0.2px;
    white-space: nowrap;

    @include break-reverse(768) {
      font-size: 18px;
    }

    .link {
      position: static;

      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .building-block-card__icon {
    margin: 0 $space-xs 0 0;
    width: 20px;
    height: 20px;
  }

  .building-block-card__description {
    font: 400 14px / 1.35 $satoshi;
    letter-spacing: 0.2px;

    @include break(575) {
      color: $neutral--40;
    }
  }

  .building-block-card__line {
    position: absolute;
    z-index: 10;
  }

  .building-block-card__line--horizontal {
    left: 50%;
    width: 32px;
    height: 23px;
  }

  .building-block-card__line--vertical {
    top: 50%;
    width: 23px;
    height: 32px;
  }

  .building-block-card__line--bottom {
    top: 100%;
    transform: translate(-16px, -1px);
  }

  .building-block-card__line--top {
    bottom: 100%;
    transform: rotate(180deg) translate(16px, -1px);
  }

  .building-block-card__line--left {
    right: 100%;
    transform: translate(1px, -16px);
  }

  .building-block-card__line--right {
    left: 100%;
    transform: rotate(180deg) translate(1px, 16px);
  }
}
