.tabs__body {
  display: flex;
  align-items: center;
}

.tabs__item {
  transition: all 0.2s ease;

  &:not(:last-of-type) {
    margin: 0 $space-sm 0 0;
  }
}

.tabs__item--active {
  border-color: $neutral--50;
}
