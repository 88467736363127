.landing-application {
  padding: $space-xl 0 ($space-md * 2);

  @include break-reverse(768) {
    padding: ($space-md * 2) 0 ($space-xl * 2);
  }

  &:before {
    background: linear-gradient(
      180deg,
      $primary--80 0%,
      rgba($primary--80, 0) 100%
    );
  }
}

.landing-application__content {
  width: 100%;
}

.landing-application__top {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
  }

  @include break-reverse(992) {
    margin: 0 0 ($space-xl * 2);
  }
}

.landing-application__bottom {
  @include break-reverse(768) {
    flex-direction: row;
    align-items: normal;
    margin: 0 -4vw;
  }

  @include break-reverse(1241) {
    margin: 0 (-$space-xl * 2);
  }

  .landing-application__block {
    @include break-reverse(768) {
      padding: 0 4vw;
    }

    @include break-reverse(1241) {
      padding: 0 ($space-xl * 2);
    }

    .application-card {
      @include break-reverse(768) {
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.landing-application__block {
  display: flex;
  justify-content: center;
  width: 100%;

  .application-card,
  .application-card__content {
    display: flex;
    flex-direction: column;
  }

  .application-card {
    @include break(767) {
      align-items: center;
    }
  }

  .application-card__content {
    max-width: 450px;

    @include break(767) {
      margin: 0 0 $space-md;
    }
  }

  .application-card__title {
    margin: 0 0 $space-xs;
    font: 500 18px / 1.3 $clashGrotesk;
    letter-spacing: 0.2px;
  }

  .application-card__description {
    font: 400 14px / 1.3 $satoshi;
    color: $neutral--10;

    @include break-reverse(768) {
      font-size: 18px;
    }
  }

  .application-card__visual {
    @include break(767) {
      display: flex;
      justify-content: center;
    }
  }
}

.landing-application__block--product {
  .application-card {
    @include break-reverse(768) {
      flex-direction: row;
      align-items: center;
    }
  }

  .application-card__content {
    @include break-reverse(768) {
      order: 1;
      padding: 0 0 0 5vw;
      width: 40%;
      max-width: 400px;
    }

    @include break-reverse(1241) {
      padding: 0 0 0 ($space-xl * 2);
    }
  }

  .application-card__visual {
    @include break-reverse(768) {
      order: 0;
      width: 60%;
    }

    img {
      @include break(767) {
        max-height: 425px;
      }
    }
  }
}

.landing-application__block--interact,
.landing-application__block--infrastructure {
  .application-card__content {
    @include break-reverse(768) {
      order: 1;
    }
  }

  .application-card__visual {
    @include break-reverse(768) {
      order: 0;
      margin: 0 0 $space-md;
    }
  }
}

.landing-application__block--interact {
  @include break(767) {
    margin: 0 0 $space-lg;
  }

  @include break-reverse(768) {
    width: 47%;
  }

  .application-card__visual {
    img {
      max-height: 485px;
    }
  }
}

.landing-application__block--infrastructure {
  @include break-reverse(768) {
    width: 53%;
  }

  .application-card__visual {
    img {
      max-height: 525px;
    }
  }
}
