.landing-structure__title {
  @include break-reverse(768) {
    margin: 0 0 $space;
  }
}

.landing-structure__subtitle {
  @include break-reverse(768) {
    margin: 0 0 $space-lg;
  }
}

.landing-structure__items-list {
  display: flex;
  flex-direction: column;

  @include break-reverse(768) {
    flex-direction: row;
    align-items: center;
    margin: 0 -4vw;
  }

  @include break-reverse(1241) {
    margin: 0 (-$space-xl);
  }
}

.landing-structure__item {
  @include break-reverse(768) {
    padding: 0 4vw;
    width: 50%;
  }

  @include break-reverse(1241) {
    padding: 0 $space-xl;
  }

  &:first-of-type {
    @include break(767) {
      margin: 0 0 $space-lg;
    }
  }

  .structure-card {
    display: flex;
    flex-direction: column;
  }

  .structure-card__title {
    margin: 0 0 $space-sm;
    font: 500 18px / 1.3 $clashGrotesk;
    letter-spacing: 0.2px;
  }

  .structure-card__description {
    margin: 0 0 $space-md;
    max-width: 475px;
    font: 400 18px / 1.3 $satoshi;
    color: $neutral--10;
  }

  .structure-card__content-list {
    display: flex;
    align-items: center;
  }
}

.landing-structure__item--certificate {
  .structure-card__content-item {
    &:last-of-type {
      margin: 0 0 0 $space-md;
    }

    img {
      max-height: 88px;

      @include break-reverse(768) {
        max-height: 114px;
      }

      @include break-reverse(992) {
        max-height: 140px;
      }
    }

    p {
      font: 500 18px / 1.55 $satoshi;
    }
  }
}

.landing-structure__item--deployment {
  .structure-card__title {
    @include break-reverse(768) {
      order: 2;
    }
  }

  .structure-card__description {
    @include break-reverse(768) {
      order: 3;
      margin: 0;
    }
  }

  .structure-card__content-list {
    @include break-reverse(768) {
      order: 1;
      margin: 0 0 $space-md;
    }
  }

  .structure-card__content-item {
    &:not(:first-of-type) {
      margin: 0 0 0 $space;
    }

    img {
      max-height: 56px;

      @include break-reverse(768) {
        max-height: 64px;
      }

      @include break-reverse(992) {
        max-height: 72px;
      }

      @include break-reverse(1241) {
        max-height: 80px;
      }
    }
  }
}
