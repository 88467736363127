.competitor-advantages {
  padding: ($space-md * 2) 0;

  @include break-reverse(768) {
    padding: ($space-xl * 2) 0;
  }
}

.competitor-advantages__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.competitor-advantages__background {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(55%, 5%) rotate(50deg);
  width: 83.5vw;
  height: 60vw;
  background: rgba(#005c9e, 0.6);
  border-radius: 83.5vw;
  opacity: 0.2;
  filter: blur(100px);

  @include break-reverse(1241) {
    width: 1000px;
    height: 725px;
    border-radius: 1000px;
  }
}

.competitor-advantages__title {
  margin: 0 0 $space-md;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
  }
}

.competitor-advantages__items-list {
  @include break-reverse(576) {
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    flex-wrap: wrap;
    margin: 0 (-$space-sm);
  }
}

.competitor-advantages__item {
  display: flex;
  justify-content: center;
  padding: 0 $space-sm;
  max-width: 385px;
  width: 100%;

  @include break-reverse(576) {
    max-width: 100%;
    width: 50%;
  }

  @include break-reverse(992) {
    width: calc(100% / 3);
  }

  &:not(:last-of-type) {
    @include break(991) {
      margin: 0 0 $space-md;
    }
  }

  .advantages-card {
    padding: $space-md $space;
    width: 100%;
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 12px;
  }

  .advantages-card__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 $space;
    width: 48px;
    height: 48px;
    background: #2b4b7b;
    border: 8px solid rgba($neutral--10, 0.05);
    border-radius: 50%;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .advantages-card__title {
    text-align: center;
    font: 600 20px / 1.5 $clashGrotesk;

    @include break-reverse(768) {
      font-size: 24px;
    }
  }
}
