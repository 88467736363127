.landing {
  background: $primary--100;
  overflow: hidden;
}

.landing__section {
  padding: ($space-md * 2) 0;

  @include break-reverse(768) {
    padding: ($space-xl * 2) 0;
  }
}

.landing__layer {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 100%;
  }

  * {
    position: relative;
    z-index: 5;
  }
}
