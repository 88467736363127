.competitor-reviews {
  padding: $space-md 0;

  @include break-reverse(768) {
    padding: ($space-md * 2) 0;
  }
}

.competitor-reviews__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.competitor-reviews__left-background {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -10%) rotate(-15deg);
  width: 60vw;
  height: 45vw;
  background: #005c9e;
  border-radius: 60vw;
  opacity: 0.2;
  filter: blur(100px);

  @include break-reverse(1241) {
    width: 725px;
    height: 525px;
    border-radius: 725px;
  }
}

.competitor-reviews__middle-background {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 25vw;
  background: #203d68;
  border-radius: 25vw;
  filter: blur(200px);

  @include break-reverse(1241) {
    width: 715px;
    height: 285px;
    border-radius: 715px;
  }
}

.competitor-reviews__slider {
  width: 100%;

  .alice-carousel__dots {
    margin: $space-xs 0 0;
  }
}

.competitor-reviews__slide {
  margin: 0 $space-sm;
  height: 100%;

  .review-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $space;
    height: 100%;
    background: rgba($primary--70, 0.6);
    border: 1px solid rgba($neutral--40, 0.6);
    border-radius: 16px;
    overflow: hidden;
  }

  .review-card__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0 0 $space;
    font: 500 16px / 1.5 $satoshi;
    letter-spacing: 0.2px;
    -webkit-line-clamp: 8;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .review-card__visual {
    margin: 0 0 $space-sm - 4;
    width: 40px;
    height: 40px;
    background: $neutral--0;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .review-card__name {
    font: 600 16px / 1.55 $clashGrotesk;
    letter-spacing: 0.2px;

    @include break-reverse(768) {
      font-size: 18px;
    }
  }

  .review-card__position,
  .review-card__company {
    margin: ($space-sm - 4) 0 0;
    font: 400 14px / 1.3 $satoshi;
  }

  .review-card__position {
    font-weight: 500;

    & + .review-card__company {
      margin: $space-xxs 0 0;
    }
  }
}
