.accordion-block__items {
  border-top: 1px solid rgba($neutral--40, 0.6);
}

.accordion-block__item {
  padding: $space-sm 0;
  border-bottom: 1px solid rgba($neutral--40, 0.6);

  @include break-reverse(768) {
    padding: $space 0;
  }

  @include break-reverse(992) {
    padding: $space-md 0;
  }
}

.accordion-block__item-title {
  position: relative;
  display: inline-block;
  padding: 0 $space-lg 0 0;
  width: 100%;
  font: 700 16px / 1.35 $satoshi;
  cursor: pointer;

  @include break-reverse(768) {
    padding: 0 $space-xl 0 0;
    font-size: 18px;
  }

  @include break-reverse(992) {
    padding: 0 $space-xxl 0 0;
  }
}

.accordion-block__item-icon {
  position: absolute;
  top: 8px;
  right: 0;
  width: 16px;
  height: 8px;
  transition: all 0.2s ease;

  @include break-reverse(768) {
    width: 24px;
    height: 12px;
  }
}

.accordion-block__item-checkbox {
  display: none;

  &:checked {
    ~ .accordion-block__item-title {
      .accordion-block__item-icon {
        transform: rotate(180deg);
      }
    }

    ~ .accordion-block__item-content {
      padding: $space-sm 0 0;
      height: auto;
      transition: all 0.1s ease;

      @include break-reverse(768) {
        padding: $space 0 0;
      }

      @include break-reverse(992) {
        padding: $space-md 0 0;
      }
    }
  }
}

.accordion-block__item-content {
  height: 0;
  font: 400 16px / 1.35 $satoshi;
  overflow: hidden;
  transition: all 0.2s ease;

  @include break-reverse(768) {
    font-size: 18px;
  }
}
