.Container_container__maG35,
.InnerPage_bg__qwXbq {
  &:has(.pricing) {
    padding: 0 !important;
    background: none;
  }
}

.pricing__title {
  margin: 0 0 $space-lg;
  text-align: center;
  font: 400 32px / 1.1 $clashGrotesk;

  @include break-reverse(768) {
    margin: 0 0 $space-xl;
    font-size: 38px;
  }

  @include break-reverse(1241) {
    margin: 0 0 ($space-md * 2);
    font-size: 60px;
  }
}
