.landing-advantages__body {
  padding: $space-lg $space-sm;
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 14px;

  @include break-reverse(768) {
    padding: $space-xl;
  }

  @include break-reverse(992) {
    padding: ($space-md * 2);
  }
}

.landing-advantages__top {
  position: relative;
  margin: 0 0 ($space-md * 2);

  @include break-reverse(768) {
    margin: 0 0 ($space-xl * 2);
  }

  @include break-reverse(992) {
    margin: 0 0 ($space-md * 4);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -32px;
    left: 0;
    width: 100%;
    height: 0.75px;
    background: rgba($neutral--40, 0.6);

    @include break-reverse(768) {
      bottom: -48px;
    }

    @include break-reverse(992) {
      bottom: -64px;
    }
  }
}

.landing-advantages__title--top {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 ($space-md * 2);
  }
}

.landing-advantages__items-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include break-reverse(768) {
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    flex-wrap: wrap;
    margin: 0 (-$space-sm);
  }
}

.landing-advantages__item {
  display: flex;
  padding: 0 $space-sm;

  @include break-reverse(768) {
    width: 50%;
  }

  @include break-reverse(992) {
    width: calc(100% / 3);
  }

  &:not(:last-of-type) {
    @include break(991) {
      margin: 0 0 $space-lg;
    }
  }

  .advantages-card {
    position: relative;
    padding: 156px 0 0;
    max-width: 335px;
  }

  .advantages-card__background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 132px;
    height: 132px;
    opacity: 0.6;
    filter: blur(50px);
  }

  .advantages-card__visual {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 155px;
    height: 155px;
  }

  .advantages-card__title,
  .advantages-card__description {
    text-align: center;
  }

  .advantages-card__title {
    margin: 0 0 $space-sm;
    font: 500 18px / 1.5 $clashGrotesk;
  }

  .advantages-card__description {
    max-width: 330px;
    font: 400 16px / 1.4 $satoshi;
  }
}

.landing-advantages__title--bottom {
  margin: 0 0 $space-sm;
}

.landing-advantages__subtitle {
  margin: 0 0 $space-lg;
}

.landing-advantages__actions {
  flex-direction: column;
  margin: 0;

  @include break-reverse(768) {
    flex-direction: row;
  }
}
