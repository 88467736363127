.integration-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-sm;
  width: 100%;
}

.integration-block__tagline {
  font: 400 14px / 1.75 $satoshi;
  text-align: center;

  @include break-reverse(768) {
    font-size: $size-point * 2;
  }
}

.integration-block__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include break-reverse(650) {
    flex-direction: unset;
    justify-content: center;
  }
}

.integration-block__field {
  position: relative;
  display: flex;
  max-width: 335px;
  width: 100%;
  min-height: 44px;
  margin: 0 0 $space-sm;

  @include break-reverse(650) {
    max-width: 360px;
    margin: 0;
  }

  &:focus-within > span {
    background-color: $primary--30;
  }
}

.integration-block__label {
  padding: $space-xs $space-sm;
  font-size: $size-point * 2;
  border-radius: 31px 0 0 31px;
  border: 1px solid $neutral--60;
  transition: all 0.2s ease;
}

.integration-block__input {
  width: 100%;
  margin-left: -1px;
  padding: 6px $space-sm $space-xs;
  color: $neutral--0;
  background-color: transparent;
  border-radius: 0 31px 31px 0;
  border: 1px solid $neutral--60;
  outline: none;
  transition: all 0.2s ease;

  &::placeholder {
    font-size: $size-point * 2;
  }

  &:focus {
    border-color: $primary--30;
  }
}

.integration-block__btn {
  background: linear-gradient(94deg, $primary--10 0%, $primary--30 100%);

  & span {
    transition: all 0.2s ease;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: inherit;
    background: $neutral--0;
    opacity: 0;
    z-index: 4;
    transition: all 0.2s ease;
  }

  &:hover {
    span {
      color: $neutral--100;
    }
  }

  &:hover::before {
    opacity: 1;
  }

  @include break-reverse(650) {
    width: fit-content;
    margin: 0 0 0 $space-sm;
  }
}
