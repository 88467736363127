/*----- common styles----- */
.btn,
.link {
  position: relative;
  transition: all 0.2s ease;
}

.btn,
.link--primary,
.link--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $space-xs $space-sm;
  font: 400 18px / 1.45 $clashGrotesk;
  background: transparent;
  color: $neutral--0;
  border: 1px solid transparent;
  border-radius: 30px;
  cursor: pointer;

  @include break-reverse(768) {
    padding: $space-xs $space;
  }
}

.btn--primary,
.link--primary {
  background: $primary--30;

  &:hover {
    background: $neutral--0;

    span {
      color: $neutral--100;
    }
  }
}

.btn--secondary,
.link--secondary {
  background: transparent 50%;
  border-color: $neutral--50;

  &:hover {
    background: $neutral--0;
    border-color: $neutral--0;

    span {
      color: $neutral--100;
    }
  }
}

.btn__content,
.link__content {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  transition: all 0.2s ease;

  .icon {
    transition: all 0.2s ease;
  }
}

/*----- buttons----- */
.btn--menu {
  padding: 0;
}

.btn--ai {
  background: transparent;

  &:hover {
    background: $primary--60;

    span {
      color: $neutral--0;
    }
  }

  .icon {
    &:first-of-type {
      margin: 0 $space-xs 0 0;
      width: 32px;
      height: 32px;
    }

    &:last-of-type {
      margin: (-$space-xxs) 0 0 $space-xxs;
      width: 20px;
      height: 20px;
    }
  }
}

/*----- links----- */
.link {
  &[target='_blank'] {
    &:after {
      content: none !important;
    }
  }
}

.link--line,
.link--no-line {
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    transition: all 0.2s ease;
  }
}

.link--line {
  &:before {
    background: $neutral--0;
  }

  &:hover {
    &:before {
      background: transparent;
    }
  }
}

.link--no-line {
  &:before {
    background: transparent;
  }

  &:hover {
    &:before {
      background: $neutral--0;
    }
  }
}
