.landing-monitoring__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.landing-monitoring__background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 100vw;
  height: 20vw;
  filter: blur(120px);

  @include break-reverse(1241) {
    transform: translate(-50%, -55%);
    width: 1150px;
    height: 275px;
  }
}

.landing-monitoring__subtitle {
  margin: 0 0 $space;
}

.landing-monitoring__visual {
  padding: ($space-md + 4) 0 0;
  max-width: 950px;
  width: 100%;
  background: rgba($primary--70, 0.4);
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 14px;

  img {
    background: linear-gradient(
      to bottom,
      rgba(#0a0e24, 1) 0%,
      rgba(#0a0e24, 0.5) 100%
    );
  }

  @include break-reverse(576) {
    padding: $space-xl ($space / 2) ($space / 2);
    img {
      border-radius: 12px;
    }
  }

  @include break-reverse(768) {
    padding: $space-xxl ($space / 2) ($space / 2);
  }

  &:before {
    content: '';
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    height: 0.75px;
    background: rgba($neutral--40, 0.6);

    @include break-reverse(768) {
      top: 44px;
    }
  }
}

.landing-monitoring__visual-dots {
  position: absolute;
  top: 14px;
  left: 16px;
  width: 9px;
  height: 9px;
  background: $neutral--0;
  border-radius: 50%;

  @include break-reverse(768) {
    top: 18px;
    width: 10px;
    height: 10px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 9px;
    height: 9px;
    background: $neutral--0;
    border-radius: 50%;

    @include break-reverse(768) {
      width: 10px;
      height: 10px;
    }
  }

  &:before {
    right: -17px;
  }

  &:after {
    right: -34px;
  }
}

.landing-monitoring__visual-icon {
  width: 100%;
}
