/*----- clash grotesk ----- */
@font-face {
  font-family: 'Clash Grotesk';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/clash-grotesk-400.woff2') format('woff2'),
    url('/fonts/clash-grotesk-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Clash Grotesk';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/clash-grotesk-500.woff2') format('woff2'),
    url('/fonts/clash-grotesk-500.ttf') format('truetype');
}

/*----- satoshi ----- */
@font-face {
  font-family: 'Satoshi';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/satoshi-400.woff2') format('woff2'),
    url('/fonts/satoshi-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/satoshi-500.woff2') format('woff2'),
    url('/fonts/satoshi-500.ttf') format('truetype');
}

/*----- Logo ----- */
@font-face {
  font-family: 'Logotype';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/logo.ttf') format('truetype');
}
