/*----- alice carousel ----- */
.alice-carousel {
  .alice-carousel__stage {
    display: flex;
  }

  .alice-carousel__stage-item {
    flex: 1 0 auto;
    height: auto;
  }

  .alice-carousel__dots {
    position: relative;
    z-index: 10;
  }

  .alice-carousel__dots-item {
    background: rgba($neutral--20, 0.5);
    transition: all 0.2s ease;

    &:hover {
      background: $primary--30;
    }

    &:not(:last-of-type) {
      margin: 0 $space-xs 0 0;
    }

    &.__active {
      background: $neutral--20;
    }
  }
}
