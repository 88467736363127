.pricing-startup {
  margin: 0 0 $space-xl;

  @include break-reverse(768) {
    margin: 0 0 ($space-lg * 2);
  }

  @include break-reverse(1241) {
    margin: 0 0 ($space-xxl * 2);
  }
}

.pricing-startup__body {
  position: relative;
  padding: $space;
  background: $primary--80;
  border: 1px solid $additional--purple;
  border-radius: 12px;
  box-shadow: 0 0 20px 0 rgba($additional--purple, 0.3);

  @include break-reverse(768) {
    padding: $space-md;
  }

  @include break-reverse(1241) {
    padding: $space-lg;
  }

  * {
    position: relative;
    z-index: 5;
  }
}

.pricing-startup__background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 135%;
  background: rgba($additional--purple, 0.7);
  border-radius: 50%;
  filter: blur(125px);

  @include break-reverse(768) {
    width: 40%;
  }

  @include break-reverse(1241) {
    width: 485px;
    border-radius: 485px;
  }
}

.pricing-startup__title {
  margin: 0 0 $space;
}

.pricing-startup__subtitle {
  margin: 0;
  max-width: 100%;
}

.pricing-startup__icon {
  margin: 0 ($space-sm - 4) 0 0;
  max-width: 30px;
  width: 100%;

  @include break-reverse(768) {
    margin: 0 $space-sm 0 0;
    max-width: 34px;
  }

  @include break-reverse(992) {
    max-width: 38px;
  }
}
