.advertise-block {
  padding: $space-xl 0;

  @include break-reverse(768) {
    padding: 0 0 ($space-xl * 2);
  }
}

.advertise-block__body {
  position: relative;
  padding: $space-xl $space-sm;
  background: rgba($primary--70, 0.4);
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 14px;
  overflow: hidden;

  @include break-reverse(768) {
    padding: $space-xl;
  }

  @include break-reverse(992) {
    padding: ($space-md * 2);
  }

  * {
    position: relative;
    z-index: 5;
  }
}

.advertise-block__background {
  position: absolute;
  z-index: 0;
  top: 50%;
  right: 0;
  transform: translate(5%, -50%);
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 150px 0;
  border-left: 1075px solid $primary--20;
  filter: blur(120px);

  @include break-reverse(768) {
    left: 0;
    right: auto;
    transform: translate(-65%, -50%);
  }

  @include break-reverse(992) {
    transform: translate(-60%, -50%);
  }
}

.advertise-block__content {
  @include break-reverse(768) {
    align-items: flex-start;
    margin: 0 20% 0 0;
  }
}

.advertise-block__title {
  margin: 0 0 $space;
  text-align: center;
  font: 500 24px / 1.25 $clashGrotesk;

  @include break-reverse(768) {
    max-width: fit-content;
    text-align: left;
    font-size: 36px;
    font-weight: 400;
  }

  @include break-reverse(992) {
    font-size: 48px;
  }
}

.advertise-block__subtitle {
  margin: 0 0 $space-md;

  @include break-reverse(768) {
    margin: 0 0 $space-lg;
    text-align: left;
  }
}

.advertise-block__actions {
  margin: 0;

  @include break-reverse(768) {
    justify-content: flex-start;
  }
}

.advertise-block__visual {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  height: 210px;

  @include break-reverse(992) {
    right: -64px;
    height: 250px;
  }

  @include break-reverse(1241) {
    height: 290px;
  }
}

.advertise-block__button {
  &:hover {
    .icon {
      stroke: $neutral--100;
    }
  }

  .btn__content {
    align-items: center;
    gap: $space-xs;

    span {
      @include break-reverse(768) {
        order: 1;
      }
    }
  }

  .icon {
    transform: translate(0, -1px);
    width: 20px;
    height: 20px;
    stroke: $neutral--0;
    stroke-width: 1.5px;

    @include break-reverse(768) {
      order: 0;
    }
  }
}
