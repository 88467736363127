.logo-block {
  color: $neutral--0;

  &:hover {
    color: rgba($neutral--0, 0.5) !important;
  }
}

.logo-block__logo-image {
  width: 50px;
}

.logo-block__logo-text {
  margin: 0 0 0 0.4rem;
  font-family: Logotype, system-ui, sans-serif;
  font-size: 1.125rem;
}
