h1,
h2,
h3,
h4,
p,
hr {
  padding: initial;
  margin: initial;
}
hr {
  border: initial;
}

:root {
  --website-dark-bg: #080a1e;
}

/**
 * Basic REM size
 */
html {
  font-size: 14px;
  font-weight: 400;
  scroll-behavior: smooth;

  @screen lg {
    font-size: 15px;
  }
  @screen xl {
    font-size: 16px;
  }
}

/**
 * Set specific styles
 */
body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--website-dark-bg);

  @apply font-base;
  @apply text-white;
  @apply text-base;
  max-width: 100%;
}

h1,
h2,
h3,
h4 {
  @apply font-header;
}

h1 {
  @apply m-0 mb-6 lg:mb-8 p-0;
  font-size: 2.375rem; /* 38rem */
  text-wrap: balance;
  @screen md {
    font-size: 3rem; /* 48px */
  }
  line-height: 3.429rem; /* 48px */
}

h2,
.likeH2 {
  @apply m-0 my-5 p-0;
  font-size: 2rem; /* 32px */
  @screen md {
    @apply my-7;
    font-size: 2.375rem; /* 38px */
  }
  line-height: 1.2;
}

h3,
.likeH3 {
  @apply m-0 my-6 p-0;
  font-size: 1.5rem; /* 24px */
  @screen md {
    font-size: 2rem; /* 32px */
  }
  line-height: 1.55; /* 46px */
}

h3.big {
  @apply m-0 my-6 p-0;
  font-size: 1.75rem; /* 28px */
  @screen md {
    font-size: 2rem; /* 32px */
  }
  line-height: 1.55; /* 46px */
}

h4,
.likeH4 {
  @apply m-0 my-4 p-0;
  font-size: 1.25rem; /* 20px */
  line-height: 1.6; /* 32px */
}

h5 {
  @apply text-base;
  line-height: 1.445; /* 26px */
}

p {
  @apply m-0 p-0;
  line-height: 1.6;

  @apply text-white text-opacity-80;
}
p + p {
  @apply mt-6;
}

a {
  @apply no-underline cursor-pointer;
  @apply hover:underline;
  @apply transition-colors duration-150;
  color: inherit;
}

button,
a {
  &.button {
    @apply inline-block;
    @apply border rounded-md;
    @apply text-sm text-center;
    @apply py-4 px-5;
    @apply transition-all duration-200;
    @apply hover:no-underline;
    line-height: 1.5rem;

    @screen md {
      @apply py-2.5;
      @apply w-auto;
    }
  }
}

button,
a {
  &.button {
    @apply border-transparent text-black bg-white;
    box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);

    &.size-l {
      padding: 1rem 1.5rem;
      font-weight: 600;
    }

    &.size-m {
      @apply px-4 py-2;
    }

    &:hover {
      box-shadow: 0 0 24px rgba(255, 255, 255, 0.9);
    }

    &:focus,
    &:active {
      box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
      border-color: #5287c5;
    }

    &:disabled,
    &.disabled {
      background-color: #c7d4dd;
      color: #616e77;

      &:hover,
      &:focus,
      &:active {
        @apply border-transparent;
        background-color: #c7d4dd;
        box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
      }
    }

    &.signUp {
      background-color: #6c57f2;

      &:hover,
      &:focus,
      &:active {
        @apply border-transparent;
        background-color: #5e4ae3;
        box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
      }
    }

    &.blue {
      @apply text-white bg-seljukBlue;

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
        color: #000;
        box-shadow: 0 1px 2px rgba(255, 255, 255, 0.5);
      }

      &:focus,
      &:active {
        @apply border-seljukBlue;
      }

      &:disabled,
      &.disabled {
        background-color: #c8d9ed;
        color: #616e77;

        &:hover,
        &:focus,
        &:active {
          @apply border-transparent;
          background-color: #c8d9ed;
          box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
        }
      }
    }

    &.secondary {
      @apply text-white bg-transparent border-white;

      &:hover,
      &:focus,
      &:active {
        @apply text-black bg-white border-white;
        box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
      }

      &:focus,
      &:active {
        @apply border-seljukBlue;
      }

      &:disabled,
      &.disabled {
        @apply bg-transparent;
        color: #c7d4dd;
        border-color: #c7d4dd;

        &:hover,
        &:focus,
        &:active {
          @apply border-transparent bg-transparent;
          color: #c7d4dd;
          border-color: #c7d4dd;
          box-shadow: 0 1px 2px rgba(22, 106, 135, 0.05);
        }
      }
    }

    &.button__bordered {
      @apply text-white bg-transparent border;
      border-color: #4e91ef;
      box-shadow: initial;

      &:hover,
      &:focus,
      &:active {
        border-color: #4e91ef;
        background-color: #4e91ef;
      }

      &:focus,
      &:active {
        @apply border-seljukBlue;
      }

      &_white {
        @apply border-white;
      }
    }

    &.button__rounded {
      @apply rounded-full;
    }
  }
}

.image_desktop {
  @apply hidden;

  @screen md {
    @apply block;
  }
}

.image_mobile {
  @apply block;
  img {
    @apply w-full;
  }

  @screen md {
    @apply hidden;
  }
}
