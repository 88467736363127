.pricing-faq {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 $space-xxl;
  }

  @include break-reverse(1241) {
    margin: 0 0 ($space-lg * 2);
  }
}

.pricing-faq__title {
  text-align: left;
}
