.banner-block {
  padding: $space-sm 0 ($space * 3);

  @include break-reverse(768) {
    padding: $space 0;
  }
}

.banner-block__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.banner-block__background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 40vw;
  height: 25vw;
  filter: blur(10vw);

  @include break-reverse(1241) {
    transform: translate(-50%, -85%);
    width: 500px;
    height: 315px;
  }
}

.banner-block__title {
  margin: 0 auto $space-md;
  text-align: center;
  font: 400 26px / 1.1 $clashGrotesk;

  @include break-reverse(768) {
    margin: 0 0 $space-lg;
    max-width: fit-content;
    text-align: left;
    font-size: 38px;
  }

  @include break-reverse(1241) {
    font-size: 54px;
  }
}

.banner-block__actions {
  flex-direction: column;

  @include break-reverse(768) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.banner-block__button--watch,
.banner-block__button--book {
  &:hover {
    .icon {
      stroke: $neutral--100;
    }
  }

  .btn__content {
    gap: $space-xs;

    span {
      @include break-reverse(768) {
        order: 1;
      }
    }
  }

  .icon {
    transform: translate(0, -1px);
    width: 20px;
    height: 20px;
    stroke: $neutral--0;
    stroke-width: 1.5px;

    @include break-reverse(768) {
      order: 0;
    }
  }
}

.banner-block__visual {
  padding-bottom: 51.38%;
  position: relative;
  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    aspect-ratio: 290/149;
  }
  picture {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
