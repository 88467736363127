.landing-connectors {
  @include break-reverse(768) {
    padding: ($space-lg * 2) 0;
  }
}

.landing-connectors__body {
  position: relative;

  * {
    position: relative;
    z-index: 5;
  }
}

.landing-connectors__background {
  position: absolute;
  bottom: 17.5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 65vw;
  height: 38vw;
  filter: blur(65px);

  @include break-reverse(768) {
    top: 30%;
    bottom: auto;
    left: 10%;
    transform: none;
    width: 35vw;
    height: 20vw;
    filter: blur(10vw);
  }

  @include break-reverse(1241) {
    left: 9%;
    width: 400px;
    height: 235px;
  }
}

.landing-connectors__subtitle {
  @include break-reverse(768) {
    margin: 0 0 $space-md;
  }
}

.landing-connectors__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include break(767) {
    padding: 0 0 515px;
  }
}

.landing-connectors__visual {
  width: 100%;

  @include break(767) {
    position: absolute;
    left: 50%;
    bottom: 0;
    min-width: 835px;
    transform: translate(-33.5%, 0);
  }

  @include break-reverse(768) {
    transform: translate(-1%, 0);
    max-height: 800px;
  }

  @include break-reverse(1241) {
    transform: translate(-5%, 0);
  }
}

.landing-connectors__description {
  z-index: 10;
  margin: 0 0 0 (-$space-lg);
  max-width: 275px;

  @include break-reverse(768) {
    position: absolute;
    top: 0;
    left: 62%;
    margin: 0;
    max-width: 32.5vw;
    width: 100%;
    height: 100%;
  }

  @include break-reverse(992) {
    max-width: 300px;
  }

  @include break-reverse(1241) {
    left: 58%;
  }
}

.landing-connectors__description-item {
  &:first-of-type {
    margin: 0 0 $space-md;

    @include break-reverse(768) {
      position: absolute;
      top: 20.75%;
      margin: 0;
    }

    @include break-reverse(992) {
      top: 21%;
    }

    .description-card__vector {
      @include break(767) {
        right: 1%;
        transform: translate(0, -15%);
      }
    }
  }

  &:last-of-type {
    @include break-reverse(768) {
      position: absolute;
      top: 58.75%;
    }

    @include break-reverse(992) {
      top: 59%;
    }

    .description-card__vector {
      @include break(767) {
        right: 11%;
        transform: translate(0%, -4%);
      }
    }
  }

  .description-card {
    h3,
    p {
      font: 500 16px / 1.6 $satoshi;

      @include break-reverse(992) {
        font-size: 18px;
      }
    }
  }

  .description-card__title {
    margin: 0 0 $space-xs;

    @include break-reverse(768) {
      margin: 0 0 $space-sm;
    }
  }

  .description-card__vector {
    @include break(767) {
      position: absolute;
      top: 100%;
      width: 11.5px;
      height: 300px;
    }
  }
}
