.pricing-plans {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 $space-xxl;
  }

  @include break-reverse(1241) {
    margin: 0 0 ($space-lg * 2);
  }
}

.pricing-plans__body {
  margin: 0 auto;
  max-width: 750px;
}

.pricing-plans__top,
.pricing-plans__bottom {
  padding: $space;
  width: 100%;
  background: $primary--70;
  border: 1px solid rgba($neutral--40, 0.6);
  border-radius: 14px;
  box-shadow: 0 -6px 20px 0 rgba($neutral--80, 0.6);

  @include break-reverse(768) {
    padding: $space-md;
  }

  @include break-reverse(1241) {
    padding: $space-lg;
    box-shadow: 0 -6px 40px 0 rgba($neutral--80, 0.6);
  }
}

.pricing-plans__top {
  position: relative;
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 $space-xxl;
  }

  @include break-reverse(1241) {
    margin: 0 0 ($space-lg * 2);
  }
}

.pricing-plans__bottom {
  .pricing-plans__block {
    padding: $space;
    background: $primary--60;
    border-radius: 10px;

    @include break-reverse(768) {
      padding: $space-md;
    }

    &:first-of-type {
      position: relative;
      margin: 0 0 $space;

      .plans-card__icon {
        margin: -4px ($space-sm - 4px) 0 0;

        @include break-reverse(768) {
          margin: -5px $space-sm 0 0;
        }

        @include break-reverse(992) {
          margin: -6.5px $space-sm 0 0;
        }
      }
    }

    &:nth-of-type(2) {
      .plans-card__icon {
        margin: -4.5px ($space-sm - 4px) 0 0;

        @include break-reverse(768) {
          margin: -6px $space-sm 0 0;
        }

        @include break-reverse(992) {
          margin: -7px $space-sm 0 0;
        }
      }
    }

    .plans-card__icon {
      max-width: 28px;
      width: 100%;

      @include break-reverse(768) {
        max-width: 32px;
      }

      @include break-reverse(992) {
        max-width: 36px;
      }
    }
  }
}

.pricing-plans__block {
  .plans-card__title {
    margin: 0 0 $space;
  }

  .plans-card__subtitle {
    margin: 0;
  }
}

.pricing-plans__actions {
  margin: $space 0 0;

  @include break-reverse(768) {
    margin: $space-md 0 0;
  }
}

.pricing-plans__button {
  width: fit-content;
}

.pricing-plans__arrow-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;

  @include break-reverse(768) {
    height: 56px;
  }

  @include break-reverse(1241) {
    height: 80px;
  }
}

.pricing-plans__plus-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -8px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: $primary--40;
  border-radius: 50%;

  @include break-reverse(768) {
    transform: translate(-50%, -13px);
    width: 50px;
    height: 50px;
  }

  .icon {
    width: 16px;
    height: 16px;

    @include break-reverse(768) {
      width: 21px;
      height: 21px;
    }
  }
}
